<template>
  <div class="Korea2 pc">
    <div class="Korea2Max" id="Korea2Max">
      <div class="Korea2_body">
        <img src="../../assets/Korea2/1.webp" class="Korea2_1" />
        <img src="../../assets/Korea2/2.webp" class="Korea2_2" />
        <img src="../../assets/Korea2/3.webp" class="Korea2_3" />
        <img src="../../assets/Korea2/4.webp" class="Korea2_4" />
        <img src="../../assets/Korea2/5.webp" class="Korea2_5" />
        <img src="../../assets/Korea2/6.webp" class="Korea2_6" />
        <img src="../../assets/Korea2/7.webp" class="Korea2_7" />
        <img src="../../assets/Korea2/ban-1.webp" class="Korea2_8" />
        <!--  -->
        <img src="../../assets/Korea2/21.webp" class="Korea2_9" />
        <img src="../../assets/Korea2/22.webp" class="Korea2_10" />
        <img src="../../assets/Korea2/23.webp" class="Korea2_11" />
        <img src="../../assets/Korea2/24.webp" class="Korea2_12" />
        <img src="../../assets/Korea2/ban-1.webp" class="Korea2_8" />
        <!--  -->
        <img src="../../assets/Korea2/31.webp" class="Korea2_9" />
        <img src="../../assets/Korea2/32.webp" class="Korea2_13" />
        <img src="../../assets/Korea2/33.webp" class="Korea2_14" />
        <img src="../../assets/Korea2/34.webp" class="Korea2_15" />
        <img src="../../assets/Korea2/35.webp" class="Korea2_16" />
        <img src="../../assets/Korea2/ban-1.webp" class="Korea2_8" />
        <!--  -->
      </div>
      <img src="../../assets/Korea2/41.webp" class="Korea2_17" />
      <div class="Korea2_body">
        <img src="../../assets/Korea2/42.webp" class="Korea2_18" />
        <img src="../../assets/Korea2/43.webp" class="Korea2_19" />
        <img src="../../assets/Korea2/44.webp" class="Korea2_20" />
        <img src="../../assets/Korea2/45.webp" class="Korea2_21" />
        <img src="../../assets/Korea2/ban-1.webp" class="Korea2_8" />
      </div>
    </div>
    <a :href="url" target="_blank"
      ><img src="../../assets/Korea2/ban-1.webp" class="bottom_img"
    /></a>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, Swipe, SwipeItem, Popup, Loading } from "vant";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Loading.name]: Loading,
  },
  data() {
    return {
      url: "",
    };
  },
  created() {},
  mounted() {
    this.url = sessionStorage["url"] || "";
    window.addEventListener("load", () => {
      if (window.innerWidth >= 1441) {
        document.querySelector("#app").style.height =
          document.querySelector("#Korea2Max").clientHeight * 0.5 + "px";
      }
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.Korea2 {
  min-height: 100vh;
  font-size: 0;
  .Korea2Max {
    padding-bottom: 250px;
  }
  .Korea2_body {
    padding: 0 190px;
    text-align: center;
    .Korea2_2 {
      width: 970px;
      margin-top: 25px;
    }
    .Korea2_3 {
      margin-top: 25px;
      width: 1010px;
    }
    .Korea2_4 {
      margin-top: 25px;
      width: 880px;
    }
    .Korea2_5 {
      margin-top: 25px;
      width: 1030px;
    }
    .Korea2_6 {
      margin-top: 35px;
      width: 980px;
    }
    .Korea2_7 {
      margin-top: 33px;
      width: 560px;
    }
    .Korea2_8 {
      margin-top: 25px;
      width: 1050px;
    }
    .Korea2_9 {
      width: 1027px;
      margin-top: 45px;
    }
    .Korea2_10 {
      width: 1015px;
      margin-top: 30px;
    }
    .Korea2_11 {
      width: 1030px;
      margin-top: 40px;
    }
    .Korea2_12 {
      width: 570px;
      margin-top: 23px;
    }
    .Korea2_13 {
      width: 1020px;
      margin-top: 30px;
    }
    .Korea2_14 {
      width: 1011px;
      margin-top: 45px;
    }
    .Korea2_15 {
      width: 1018px;
      margin-top: 42px;
    }
    .Korea2_16 {
      width: 570px;
      margin-top: 30px;
    }
    .Korea2_18 {
      width: 885px;
      margin-top: 35px;
    }
    .Korea2_19 {
      width: 635px;
      margin-top: 42px;
    }
    .Korea2_20 {
      width: 950px;
      margin-top: 50px;
    }
    .Korea2_21 {
      width: 560px;
      margin-top: 42px;
    }
  }
  .Korea2_17 {
    margin-top: 60px;
  }
  .bottom_img {
    width: calc(100% - 100px);
    margin: 0 50px;
    position: fixed;
    bottom: 0;
  }
}
@media screen and (min-width: 1441px) {
  .Korea2Max {
    transform: scale(0.5);
    transform-origin: top center; /* 缩放的原点为元素中心 */
  }
  .bottom_img {
    transform: scale(0.5);
    transform-origin: bottom center; /* 缩放的原点为元素中心 */
  }
}
</style>
